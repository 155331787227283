import { ReactNode, useCallback, useState } from 'react';
import ContactServiceSupport from '../../../../components/common/ContactServiceSupport';
import PrivcayPolicy from '../../../../components/common/PrivcayPolicy';
import TermsOfUse from '../../../../components/common/TermsOfUse';
import Button from '../../../../components/ui/Button';
import Drawer from '../../../../components/ui/Drawer';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/ui/Dropdown';

type KEYS = 'gcu' | 'privacyPolicy' | 'serviceSupport';

type ITEM = { key: KEYS; label: string; icon: string; component: ReactNode };

const ITEMS: ITEM[] = [
	{
		key: 'privacyPolicy',
		label: 'Politique de confidentialité',
		icon: 'HeroDocumentText',
		component: <PrivcayPolicy />,
	},
	{
		key: 'gcu',
		label: "Conditions d'utilisation",
		icon: 'HeroDocumentText',
		component: <TermsOfUse />,
	},
	{
		key: 'serviceSupport',
		label: 'Service support',
		icon: 'HeroChatBubbleLeftRight',
		component: <ContactServiceSupport />,
	},
];

const CommonHeaderPartial = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [activeItem, setActiveItem] = useState<ITEM | null>(null);

	const onItemClick = useCallback(
		(item: ITEM) => {
			setIsDrawerOpen(true);
			setActiveItem(item);
		},
		[setIsDrawerOpen, setActiveItem],
	);

	return (
		<div className='relative'>
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button icon='HeroInformationCircle' aria-label='Notification' />
				</DropdownToggle>
				<DropdownMenu
					placement='bottom-end'
					className='flex flex-col flex-wrap divide-y divide-dashed divide-zinc-500/50 p-4 [&>*]:py-4'>
					{ITEMS.map((item) => (
						<DropdownItem
							key={item.key}
							icon={item.icon}
							onClick={() => onItemClick(item)}>
							{item.label}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>

			{activeItem ? (
				<Drawer title={activeItem.label} isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
					{activeItem.component}
				</Drawer>
			) : null}
		</div>
	);
};

export default CommonHeaderPartial;
