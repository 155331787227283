import { RouteProps } from 'react-router-dom';
import { adminPages } from '../../config/pages.config';

import AdminDeliveryCompanyDetailsPage from '../../pages/admin/pages/AdminDeliveryCompanyDetails.page';

import AdminOrderPage from '../../pages/admin/pages/AdminOrder.page';

import AdminContactUsMessagesPage from '../../pages/admin/pages/AdminContactUsMessages.page';
import AdminCustomerDetailsPage from '../../pages/admin/pages/AdminCustomerDetails.page';
import AdminCustomersPage from '../../pages/admin/pages/AdminCustomers.page';
import AdminDeliveryServicesPage from '../../pages/admin/pages/AdminDeliveryServices.page';
import AdminMailManagerPage from '../../pages/admin/pages/AdminMailManagerPage';
import AdminOrdersPage from '../../pages/admin/pages/AdminOrders.page';
import AdminSettingsPage from '../../pages/admin/pages/AdminSettings.page';

const adminRoutes: RouteProps[] = [
	{
		path: adminPages.orders.to,
		element: <AdminOrdersPage />,
	},
	{
		path: adminPages.order.to,
		element: <AdminOrderPage />,
	},

	{
		path: adminPages.customers.to,
		element: <AdminCustomersPage />,
	},
	{
		path: adminPages.deliveryCompanies.to,
		element: <AdminDeliveryServicesPage />,
	},
	{
		path: adminPages.customer.to,
		element: <AdminCustomerDetailsPage />,
	},
	{
		path: adminPages.deliveryCompany.to,
		element: <AdminDeliveryCompanyDetailsPage />,
	},
	{
		path: adminPages.settings.to,
		element: <AdminSettingsPage />,
	},
	{
		path: adminPages.mails.to,
		element: <AdminMailManagerPage />,
	},
	{
		path: adminPages.contactMessages.to,
		element: <AdminContactUsMessagesPage />,
	},
];

export default adminRoutes;
