import CommonHeaderPartial from '../_partial/CommonHeader.partial';

const DefaultHeaderRightCommon = () => {
	return (
		<>
			{/* <NotificationPartial /> */}
			{/* <SettingsPartial /> */}
			<CommonHeaderPartial />
		</>
	);
};

export default DefaultHeaderRightCommon;
