import { CuDeliveryAddress } from '@devschap/order-point-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../components/form/Input';
import Button from '../../../components/ui/Button';
import Modal from '../../../components/ui/Modal';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';
import DeliveryAddress from './DeliveryAddress';

type Props = {
	withSelection?: boolean;
};

const CustomerDeliveryAddresses = ({ withSelection }: Props) => {
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [label, setLabel] = useState<string>('');
	const [telephone, setTelephone] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedAddress, setSelectedAddress] = useState<CuDeliveryAddress | null>(null);

	const { deliveryAddresses, addDeliveryAddress, setDeliveryAddresses } = useUserStore();
	const { AddressForm, addressFormik, country } = useAddressForm({ countryPlaceholder: 'Pays' });
	const { axiosFetch, loading } = useAxiosFunction();
	const dispatch = useDispatch();
	const customToast = useCustomToast();

	useEffect(() => {
		const getAddresses = async () => {
			if (loading) return;

			try {
				const response = await axiosFetch<{ addresses: CuDeliveryAddress[] }>({
					method: 'GET',
					url: '/api/customer/delivery-addresses',
				});
				dispatch(setDeliveryAddresses(response?.addresses ?? []));
			} catch (error) {
				customToast.error('Une erreur est survenue');
			}
		};

		getAddresses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const createAddress = async () => {
		try {
			setIsLoading(true);
			const response = await axiosFetch<{ address: CuDeliveryAddress }>({
				method: 'POST',
				url: '/api/customer/delivery-address',
				requestConfig: {
					label,
					telephone,
					address: {
						countryIso: country,
						city: addressFormik.values.city,
						district: addressFormik.values.district,
						postalCode: addressFormik.values.postalCode,
					},
				},
			});
			if (response?.address) {
				dispatch(addDeliveryAddress(response.address));
				setModalStatus(false);
				addressFormik.resetForm();
				setLabel('');
				setTelephone('');
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
		} finally {
			setIsLoading(false);
		}
	};

	const disable = addressFormik.values.district === '' || label === '' || telephone === '';
	return (
		<>
			<div className='flex justify-between items-center'>
				<span className='font-bold'>Vos adresses de livraison</span>

				<Button
					variant='solid'
					color='blue'
					icon='HeroPlusSmall'
					onClick={() => setModalStatus(true)}>
					Nouvelle addresse
				</Button>
			</div>

			<div>
				{deliveryAddresses.map((d) => (
					<DeliveryAddress
						deliveryAddress={d}
						key={d.id}
						selectedAddressId={selectedAddress?.id}
						setSelectedAddress={withSelection ? setSelectedAddress : undefined}
					/>
				))}
			</div>

			<Modal
				isOpen={modalStatus}
				setIsOpen={setModalStatus}
				title='Ajouter une addresse de livraison'
				footer={
					<Button
						isDisable={disable}
						isLoading={isLoading}
						variant='solid'
						color='blue'
						className='w-full'
						onClick={createAddress}>
						Ajouter
					</Button>
				}>
				<Input
					className='mb-4'
					id='label'
					name='label'
					type='text'
					onChange={(e) => setLabel(e.target.value)}
					value={label}
					placeholder='Lieu de livraison'
				/>

				<Input
					className='mb-4'
					id='phone'
					name='phone'
					type='tel'
					onChange={(e) => setTelephone(e.target.value)}
					value={telephone}
					placeholder='Télephone'
				/>

				{AddressForm}
			</Modal>
		</>
	);
};

CustomerDeliveryAddresses.defaultProps = {
	withSelection: false,
};

export default CustomerDeliveryAddresses;
