import useUserStore from '../../hooks/useUserStore';
import Alert from '../ui/Alert';
import CopyButton from '../ui/CopyButton';

const DeliveryInviteCustomerBanner = () => {
	const { state: userState } = useUserStore();

	if (!userState.deliveryService?.referralCode) return null;

	return (
		<Alert className='border-transparent my-4' title='Invitez vos clients!' variant='outline'>
			<span className='block mb-2 text-black'>
				Afin que les commandes de vos clients vous soient directement attribuées, veuillez
				leur transmettre votre code de parrainage. Ils devront l'indiquer lors de leur
				inscription sur la plateforme.
			</span>
			<CopyButton
				text={userState.deliveryService.referralCode}
				className='!bg-blue-500 !px-2 !text-white'
			/>
		</Alert>
	);
};

export default DeliveryInviteCustomerBanner;
