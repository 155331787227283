import { OrOrderHistory } from '@devschap/order-point-types/types/order';
import { ReactNode, useMemo } from 'react';
import { TimelineItem } from '../../../components/Timeline';
import { TColors } from '../../../types/colors.type';
import { OrderStatus } from '../../../types/shared-enums';
import { formatDate } from '../../../utils/ui.util';

interface ActionDetails {
	item: OrOrderHistory;
}

type ItemProps = {
	icon: string | undefined;
	children: ReactNode | undefined;
	color: TColors | undefined;
};

const OrderTimelimeItem = ({ item }: ActionDetails) => {
	const formatedDate = formatDate(item.addedAt, true);

	const { icon, color, children }: ItemProps = useMemo(() => {
		switch (item.action) {
			case 'create':
				return {
					icon: 'HeroPlusCircle',
					children: `Commande créée le ${formatedDate}`,
					color: 'emerald',
				};
			case 'update-product':
				return {
					icon: 'HeroPencilSquare',
					children: `Un produit de cette commande a été traité le ${formatedDate}`,
					color: 'zinc',
				};
			case 'validate':
				return {
					icon: 'HeroCheckCircle',
					children: `Commande validée par notre service le ${formatedDate}`,
					color: 'sky',
				};
			case 'pay':
				return {
					icon: 'HeroBanknotes',
					children: `Félicitation! Vous avez effectué avec succès le paiement de votre commande le ${formatedDate}`,
					color: 'emerald',
				};
			case OrderStatus.waitingForPayment:
				return {
					icon: 'HeroCheckCircle',
					children: `Votre commande a été traitée et est en attente de paiement.`,
					color: 'amber',
				};
			case OrderStatus.inprogress:
				return {
					icon: 'HeroShoppingCart',
					children: `Votre commande est en cours.`,
					color: 'violet',
				};
			case OrderStatus.shipped:
				return {
					icon: 'HeroTruck',
					children: `Youpi !!! Votre commande a été expédiée le ${formatedDate}.`,
					color: 'emerald',
				};

			default:
				return {
					icon: undefined,
					children: undefined,
					color: undefined,
				};
		}
	}, [item.action, formatedDate]);

	return (
		<TimelineItem icon={icon} color={color}>
			<span className='text-sm'>{children}</span>
		</TimelineItem>
	);
};

export default OrderTimelimeItem;
