import { countries } from 'countries-list';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { IPhoneNumber } from '../components/PhoneNumberInput';
import { appPages, authPages } from '../config/pages.config';
import { AccountRole } from '../types/shared-enums';

export const getAccountRoleLabel = (role: AccountRole): string => {
	switch (role) {
		case AccountRole.customer:
			return 'Acheteur';
		case AccountRole.deliveryCompany:
			return 'Service de livraison';
		case AccountRole.merchant:
			return 'Marchant';

		default:
			throw new Error(`Unknown account role ${role}`);
	}
};

export const validPhoneNumber = (value: IPhoneNumber): boolean => {
	return !!value.indicatif && !!value.number;
};

export const formatPhoneNumber = (value: IPhoneNumber): string | undefined => {
	return value.number.trim() ? `${value.indicatif} ${value.number}` : undefined;
};

export const formatOptionalString = (value: string): string | undefined => {
	return value.trim() ? value : undefined;
};

export const getRedirectionRoute = (role: AccountRole) => {
	switch (role) {
		case AccountRole.anonymous:
			return authPages.onboardingPage.to;

		case AccountRole.customer:
			return appPages.dashboard.to;

		case AccountRole.deliveryCompany:
			return appPages.dashboard.to;

		case AccountRole.merchant:
			return appPages.dashboard.to;

		default:
			throw new Error('Invalid account role provided');
	}
};

export const limitText = (text: string, maxLength = 30) => {
	if (text.length > maxLength) {
		return `${text.slice(0, maxLength)}...`;
	}
	return text;
};

export const parsePhoneNumber = (phoneNumber: string | null) => {
	if (!phoneNumber) {
		return {
			indicatif: '',
			number: '',
		};
	}
	const [indicatif, number] = phoneNumber.split(' ');

	return {
		indicatif,
		number,
	};
};

export const formatDate = (date: string | undefined, showTime = false) => {
	let dateFormat = 'DD MMMM, YYYY';
	if (showTime) {
		dateFormat += ' à HH:mm';
	}
	return dayjs(date).locale('fr').format(dateFormat);
};

export const getCountryName = (code: string) => {
	const c = Object.entries(countries);
	const country = c.find((i) => i[0].toLowerCase() === code.toLowerCase())?.[1];

	return country?.name;
};

export const isInsideApp = (location: string): boolean => {
	return location.startsWith('/c/') || location.startsWith('/a/') || location.startsWith('/dc/');
};
