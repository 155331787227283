import classNames from 'classnames';
import { FC } from 'react';
import useDeviceScreen from '../../hooks/useDeviceScreen';

export interface IAvatar {
	avatars: string[];
	maxAvatars?: number;
}

const AvatarsGroup: FC<IAvatar> = ({ avatars, maxAvatars = 4 }) => {
	const isMobile = useDeviceScreen();
	const verifiedWidhLenght = isMobile && isMobile.width && isMobile.width < 600;

	const adjustedMaxAvatars = verifiedWidhLenght ? 2 : maxAvatars;

	const hiddenAvatarsCount = avatars.length - adjustedMaxAvatars;

	const classes =
		'w-12 h-12 rounded-full flex-shrink-0 border-2 border-zinc-200 bg-zinc-200 object-cover';

	return (
		<div className='flex h-fit'>
			{avatars.slice(0, adjustedMaxAvatars).map((a, index) => (
				<img
					// eslint-disable-next-line react/no-array-index-key
					key={`${index}-${a}`}
					src={a}
					alt={a}
					className={classNames('avatar-img -mr-2', classes)}
				/>
			))}
			{hiddenAvatarsCount > 0 ? (
				<div className={classNames('text-base flex justify-center items-center', classes)}>
					+{hiddenAvatarsCount}
				</div>
			) : null}
		</div>
	);
};

AvatarsGroup.defaultProps = {
	maxAvatars: 4,
};

export default AvatarsGroup;
