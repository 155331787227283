import { DcDeliveryCompanyMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import { adminPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate, getCountryName } from '../../../utils/ui.util';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'label',
		label: 'Nom',
		children: ['country', 'phoneNumber'],
	},

	{
		field: 'country',
		label: 'Pays',
	},

	{
		field: 'phoneNumber',
		label: 'Numéro',
	},
	{
		field: 'ifu',
		label: 'IFU',
		hideOnMobile: true,
	},
	{
		field: 'createdAt',
		label: 'Enregistré le',
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const AdminDeliveryServicesPage = () => {
	const { axiosFetch, loading } = useAxiosFunction();
	const navigate = useNavigate();
	const [deliveryCompanies, setDeliveryCompanies] = useState<DcDeliveryCompanyMinimal[]>([]);

	const customToast = useCustomToast();

	const getDeliveryCompanies = async () => {
		if (loading) return;

		try {
			const response = await axiosFetch<{ deliveryCompanies: DcDeliveryCompanyMinimal[] }>({
				method: 'GET',
				url: '/api/admin/delivery-companies',
			});
			setDeliveryCompanies(response?.deliveryCompanies ?? []);
		} catch (error) {
			customToast.error(
				"Une erreur s'est produite lors de la récupération des services de livraison",
			);
		}
	};

	useEffect(() => {
		getDeliveryCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openDeliveryCompanyDetailsPage = useCallback(
		(companyId: number) => {
			navigate(generatePath(adminPages.deliveryCompany.to, { companyId }));
		},
		[navigate],
	);

	const rows = useMemo(() => {
		return deliveryCompanies.map((delivery) => ({
			id: delivery.id,
			ifu: delivery.ifu,
			label: (
				<span className={delivery.actived ? 'text-black' : 'text-red-500'}>
					{delivery.label}
				</span>
			),
			country: getCountryName(delivery.address.countryIso),
			phoneNumber: `${delivery.telephone1}${delivery.telephone2 ? delivery.telephone2 : ''}`,
			createdAt: formatDate(delivery.createdAt),
			actions: (
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
					</DropdownToggle>
					<DropdownMenu placement='bottom-end'>
						<DropdownItem
							onClick={() => openDeliveryCompanyDetailsPage(delivery.id)}
							icon='HeroEye'
							colorIntensity='500'>
							voir
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			),
		}));
	}, [deliveryCompanies, openDeliveryCompanyDetailsPage]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Services de livraison'
						currentPage='Services de livraison'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Services de livraison'>
				<Container>
					<Card
						header={
							<>
								Services de livraison
								{deliveryCompanies.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{deliveryCompanies.length}
									</Badge>
								) : null}
							</>
						}
						bodyClassName='overflow-x-scroll'>
						{loading ? (
							<OpLoader />
						) : (
							<DataGrid keyPrefix='deliveryCompanies' columns={cols} rows={rows} />
						)}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminDeliveryServicesPage;
