import axios, { AxiosError, AxiosHeaders, Method, RawAxiosRequestHeaders } from 'axios';
import { useEffect, useState } from 'react';
import useCustomToast from './useToast';

function getInstance() {
	const token = localStorage.getItem('token');
	return axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: token ? `Bearer ${token}` : undefined,
		},
	});
}

const useAxiosFunction = () => {
	const [loading, setLoading] = useState(false);
	const [controller, setController] = useState();
	const customToast = useCustomToast();

	const axiosFetch = async <T>(configObj: {
		axiosInstance?: { baseURL: string; headers: RawAxiosRequestHeaders | AxiosHeaders };
		method: Method;
		url: string;
		// eslint-disable-next-line @typescript-eslint/ban-types
		requestConfig?: {};
		suppressToast?: boolean;
	}): Promise<T | undefined> => {
		// if (loading) return;

		const {
			axiosInstance = getInstance(),
			method,
			url,
			requestConfig = {},
			suppressToast,
		} = configObj;

		try {
			setLoading(true);
			const ctrl = new AbortController();
			// @ts-ignore
			setController(ctrl);

			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
			const res = await axiosInstance[method.toLowerCase()](url, {
				...requestConfig,
			});
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			return res.data as T;
		} catch (err: any) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			let toastedError: string = err.message;

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (err instanceof AxiosError && err.response?.data) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const { message } = err.response.data;

				switch (typeof message) {
					case 'object':
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						const [first, ...other]: any[] = message;
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						toastedError = first.msg;
						// @ts-ignore
						other.forEach((e: any) => {
							// @ts-ignore
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							toastedError += `\n${e?.msg ?? 'Erreur'}`;
						});

						break;
					case 'string':
						toastedError = message;
						break;

					default:
						break;
				}
			}

			if (suppressToast) {
				throw new Error(toastedError);
			} else {
				customToast.error(toastedError);
			}
			// eslint-disable-next-line consistent-return
			return;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// useEffect cleanup function
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		return () => controller && controller.abort();
	}, [controller]);

	return { loading, axiosFetch };
};

export default useAxiosFunction;
