import { OrDeliveryCompanyOrderMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import OrderStatusBadge from '../../../components/OrderStatusBadge';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import EmptyContent from '../../../components/ui/EmptyContent';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';
import AdminOrderAction from './AdminOrderAction';

type Props = {
	companyId: string;
};

const cols: DatagridColumnDef[] = [
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['code', 'status'],
	},

	{
		field: 'code',
		label: 'Code',
	},

	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'deliveryDate',
		label: 'Date de livraison',
		// hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const AdminDeliveryCompanyOrders = ({ companyId }: Props) => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();

	const [orders, setOrders] = useState<OrDeliveryCompanyOrderMinimal[]>([]);

	const getOrders = useCallback(async () => {
		try {
			const response = await axiosFetch<{ orders: OrDeliveryCompanyOrderMinimal[] }>({
				method: 'GET',
				url: `/api/admin/delivery-companies/${companyId}/orders`,
			});

			if (response?.orders) {
				setOrders(response.orders);
			}
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération des commandes");
		}
	}, [axiosFetch, companyId, customToast]);

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rows = useMemo(() => {
		return orders.map((order) => ({
			createdAt: formatDate(order.createdAt),
			code: order.code,
			status: <OrderStatusBadge status={order.status} />,
			deliveryDate: order.deliveryDate,
			actions: <AdminOrderAction orderId={order.id} />,
		}));
	}, [orders]);

	const displayContent = useMemo(() => {
		return orders.length === 0 ? (
			<EmptyContent message='Pas de commande associé a ce service' />
		) : (
			<DataGrid keyPrefix='orders' rows={rows} columns={cols} />
		);
	}, [orders, rows]);

	return (
		<div>
			<Card
				className='h-full'
				bodyClassName='overflow-x-scroll'
				header={
					<>
						Commandes
						{orders.length ? (
							<Badge className='ml-2 border-transparent text-sm' variant='outline'>
								{orders.length}
							</Badge>
						) : null}
					</>
				}>
				{loading ? <OpLoader /> : displayContent}
			</Card>
		</div>
	);
};

export default AdminDeliveryCompanyOrders;
