import { RouteProps } from 'react-router-dom';
import { deliveryCompanyPages } from '../../config/pages.config';
import DeliveryCompanyCustomersPage from '../../pages/deliveryCompany/pages/DeliveryCompanyCustomers.page';
import DeliveryCompanyManageOrderPage from '../../pages/deliveryCompany/pages/DeliveryCompanyManageOrder.page';
import DeliveryCompanyOrdersPage from '../../pages/deliveryCompany/pages/DeliveryCompanyOrders.page';
import DeliveryCompanySettingsPage from '../../pages/deliveryCompany/pages/DeliveryCompanySettings.page';

const deliveryCompanyRoutes: RouteProps[] = [
	{
		path: deliveryCompanyPages.orders.to,
		element: <DeliveryCompanyOrdersPage />,
	},
	{
		path: deliveryCompanyPages.order.to,
		element: <DeliveryCompanyManageOrderPage />,
	},
	{
		path: deliveryCompanyPages.settings.to,
		element: <DeliveryCompanySettingsPage />,
	},
	{
		path: deliveryCompanyPages.customers.to,
		element: <DeliveryCompanyCustomersPage />,
	},
];

export default deliveryCompanyRoutes;
