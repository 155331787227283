import { OrOrderMinimal } from '@devschap/order-point-types';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Card from '../../../components/ui/Card';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useOrderStore from '../../../hooks/useOrderStore';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';

import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import OrderStatusBadge from '../../../components/OrderStatusBadge';
import SectionHeader from '../../../components/SectionHeader';
import AvatarsGroup from '../../../components/ui/AvatarsGroup';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import ButtonLink from '../../../components/ui/ButtonLink';
import EmptyContent from '../../../components/ui/EmptyContent';
import { customerPages } from '../../../config/pages.config';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate } from '../../../utils/ui.util';
import OrderAction from '../components/OrderAction';

const cols: DatagridColumnDef[] = [
	{
		field: 'createdAt',
		label: 'Commande du',
		children: ['code', 'status'],
	},
	{
		field: 'code',
		label: 'Code',
	},
	{
		field: 'products',
		label: 'Produits',
	},
	{
		field: 'status',
		label: 'Statut',
	},
	{
		field: 'deliveryDate',
		label: 'Date de livraison',
		hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const CustomerOrdersPage = () => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const dispatch = useDispatch();
	const { setCustomerOrder, orders } = useOrderStore();
	const navigate = useNavigate();

	const getOrders = async () => {
		if (loading) return;

		try {
			const response = await axiosFetch<{ orders: OrOrderMinimal[] }>({
				method: 'GET',
				url: '/api/customer/orders',
			});

			dispatch(setCustomerOrder(response?.orders ?? []));
		} catch (error) {
			customToast.error("Vous n'avez aucune commande");
		}
	};

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rows = useMemo(() => {
		return orders.map((order) => ({
			createdAt: formatDate(order.createdAt),
			code: order.code,
			products: <AvatarsGroup avatars={order.items} />,
			status: <OrderStatusBadge status={order.status} />,
			deliveryDate: order.deliveryDate,
			actions: <OrderAction orderId={order.id} orderStatus={order.status} />,
		}));
	}, [orders]);

	const content = useMemo(() => {
		if (loading) {
			return <OpLoader />;
		}
		if (orders.length) {
			return <DataGrid columns={cols} rows={rows} keyPrefix='order' />;
		}
		return (
			<EmptyContent
				message={
					<div className='flex flex-col space-y-2 justify-center items-center'>
						<span>Vous n'avez pas de commande</span>
						<ButtonLink
							text='Commandez maintenant'
							showBorder
							rightIcon
							path={customerPages.newOrder.to}
						/>
					</div>
				}
			/>
		);
	}, [loading, orders, rows]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Commandes' currentPage='Commandes' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<PageWrapper name='Commandes'>
				<Container className='pt-10'>
					<SectionHeader
						title={
							<>
								Vos commandes
								{orders.length ? (
									<Badge className='ml-2 border-transparent' variant='outline'>
										{orders.length}
									</Badge>
								) : null}
							</>
						}
						action={
							<Button
								className='w-full md:w-fit'
								variant='solid'
								icon='HeroPlus'
								onClick={() => navigate(customerPages.newOrder.to)}>
								Nouvelle commande
							</Button>
						}
					/>
					<Card className='mt-6 md:mt-10'>{content}</Card>
				</Container>
			</PageWrapper>
			;
		</>
	);
};

export default CustomerOrdersPage;
