import { KeyValue, OrOrderItem } from '@devschap/order-point-types';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { SingleValue } from 'react-select';
import * as Yup from 'yup';
import ProductQuantitybadge from '../../../components/ProductQuantitybadge';
import Checkbox from '../../../components/form/Checkbox';
import FieldWrap from '../../../components/form/FieldWrap';
import Fieldset from '../../../components/form/Fieldset';
import Input from '../../../components/form/Input';
import Label from '../../../components/form/Label';
import SelectReact, { TSelectOption, TSelectOptions } from '../../../components/form/SelectReact';
import Validation from '../../../components/form/Validation';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/ui/Button';
import ExternalLink from '../../../components/ui/ExternalLink';
import RichTextEditor from '../../../components/ui/RichTextEditor/RichTextEditor';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import { OpCurrency } from '../../../types/shared-enums';

type Props = {
	item: OrOrderItem;
	onClose(refresh: boolean): void;
};

type TValues = {
	originalPrice: string;
	originalCurrency: string;
	inStock: boolean;
	accepted: boolean;
	additionalInfo: string;
};

const formSchema = Yup.object().shape({
	originalPrice: Yup.number()
		.positive()
		.when(['inStock', 'accepted'], {
			is: (inStock: boolean, accepted: boolean) => {
				return inStock && accepted;
			},
			then: (schema) => schema.required('Veuillez entrer le prix du produit'),
		}),
	originalCurrency: Yup.string().when(['inStock', 'accepted'], {
		is: (inStock: boolean, accepted: boolean) => {
			return inStock && accepted;
		},
		then: (schema) => schema.required('Veuillez sélectionner la devise'),
	}),

	inStock: Yup.boolean(),
	accepted: Yup.boolean(),
	additionalInfo: Yup.mixed(),
});

const options: TSelectOptions = [
	{
		value: OpCurrency.usd,
		label: 'USD ($)',
	},
	{
		value: OpCurrency.eur,
		label: 'Euro (€)',
	},
];

const AdminManageOrderItemForm = ({ item, onClose }: Props) => {
	const customToast = useCustomToast();
	const { axiosFetch } = useAxiosFunction();

	const [config, setConfig] = useState<KeyValue[] | null>(item.productConfig);

	const formik = useFormik({
		initialValues: {
			originalPrice: (item.originalPrice || '').toString() ?? '',
			originalCurrency: item.originalCurrency ?? '',
			inStock: item.inStock,
			accepted: item.accepted,
			additionalInfo: item.additionalInfo ?? '',
		},
		validationSchema: formSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			if (!values.accepted && !values.additionalInfo.trim()) {
				customToast.error("Veuillez dire pourquoi ce produit n'a pas été accepté");
				return;
			}

			try {
				const response = await axiosFetch<{ succeeded: boolean }>({
					method: 'PUT',
					url: '/api/admin/orders/item',
					requestConfig: {
						id: item.id,
						orderId: item.orderId,
						productConfig: config,
						...values,
						accepted: values.inStock ? values.accepted : false,
						originalPrice: values.originalPrice || null,
					},
				});
				if (response?.succeeded) {
					customToast.success('Item mis à jour');
					onClose(true);
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const onConfigChange = useCallback(
		(conf: KeyValue) => {
			setConfig((prev) => {
				if (!prev) return null;

				return prev.map((c) => {
					if (c.label === conf.label) {
						return { ...conf, active: !conf.active };
					}
					return c;
				});
			});
		},
		[setConfig],
	);

	return (
		<div className='flex flex-col space-y-6 justify-between h-full'>
			<div className='product-content flex flex-col space-y-6'>
				{item.pictureUrl ? (
					<img
						className='aspect-square rounded object-contain h-56'
						src={item.pictureUrl}
						alt={item.productName ?? item.pictureUrl}
					/>
				) : null}

				<span>{item.productName ?? item.productUrl}</span>
				<div className='flex justify-between items-center'>
					<ProductQuantitybadge quantity={item.quantity} />
					<ExternalLink url={item.productUrl} />
				</div>
				<div>
					<span>
						<span className='text-blue-500'>Instructions</span> :
						{item.instructions ? item.instructions : null}
					</span>
				</div>

				<form className='flex flex-col gap-4' noValidate>
					<Fieldset legend='Statut'>
						<div>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.accepted}
								invalidFeedback={formik.errors.accepted}>
								<Checkbox
									variant='switch'
									name='accepted'
									label='Accepté'
									onChange={formik.handleChange}
									checked={formik.values.accepted}
								/>
							</Validation>
						</div>
						{formik.values.accepted ? (
							<div>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.inStock}
									invalidFeedback={formik.errors.inStock}>
									<Checkbox
										variant='switch'
										name='inStock'
										label='En stock'
										onChange={formik.handleChange}
										checked={formik.values.inStock}
									/>
								</Validation>
							</div>
						) : null}
					</Fieldset>

					{config?.length ? (
						<Fieldset legend='Configuration'>
							{config.map((c) => (
								<Checkbox
									key={c.label}
									variant='switch'
									label={
										<span>
											{c.label}: {c.value}
										</span>
									}
									onChange={() => onConfigChange(c)}
									checked={c.active}
								/>
							))}
						</Fieldset>
					) : null}

					{formik.values.inStock && formik.values.accepted ? (
						<div className='flex space-x-3'>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.originalPrice}
									invalidFeedback={formik.errors.originalPrice}>
									<Label>
										Prix original
										<FieldWrap
											firstSuffix={
												<Icon icon='HeroBanknotes' className='mx-2' />
											}>
											<Input
												name='originalPrice'
												placeholder='Prix du vendeur'
												value={formik.values.originalPrice}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FieldWrap>
									</Label>
								</Validation>
							</div>
							<div className='w-full'>
								<Validation
									isValid={formik.isValid}
									isTouched={formik.touched.originalCurrency}
									invalidFeedback={formik.errors.originalCurrency}>
									<Label>
										Dévise
										<SelectReact
											options={options}
											name='originalCurrency'
											placeholder='Sélectionnez'
											value={options.find(
												(opt) =>
													opt?.value === formik.values.originalCurrency,
											)}
											onChange={(value) => {
												formik.setFieldValue(
													'originalCurrency',
													(value as SingleValue<TSelectOption>)?.value,
												);
											}}
										/>
									</Label>
								</Validation>
							</div>
						</div>
					) : null}

					<div>
						<Label>Information</Label>
						<RichTextEditor
							onChange={(value) => formik.setFieldValue('additionalInfo', value)}
							value={formik.values.additionalInfo}
							fullHeight={false}
						/>
					</div>
				</form>
			</div>

			<div className='flex space-x-4 justify-end'>
				<Button
					onClick={() => formik.handleSubmit()}
					variant='solid'
					isLoading={formik.isSubmitting}>
					Mettre à jour
				</Button>
			</div>
		</div>
	);
};

export default AdminManageOrderItemForm;
