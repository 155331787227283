import { CuCustomerMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import { adminPages } from '../../../config/pages.config';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import { formatDate, getCountryName } from '../../../utils/ui.util';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'fullName',
		label: 'Nom complet',
		children: ['country', 'phoneNumber'],
	},
	{
		field: 'country',
		label: 'Pays',
	},
	{
		field: 'phoneNumber',
		label: 'Numéro',
	},
	{
		field: 'createdAt',
		label: 'Inscrit le',
		// hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const AdminCustomersPage = () => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const navigate = useNavigate();

	const [customers, setCustomers] = useState<CuCustomerMinimal[]>([]);

	const getCustomers = async () => {
		if (loading) return;
		try {
			const response = await axiosFetch<{ customers: CuCustomerMinimal[] }>({
				method: 'GET',
				url: '/api/admin/customers',
			});
			setCustomers(response?.customers ?? []);
		} catch (error) {
			customToast.error("Une erreur s'est produite lors de la récupération des clients");
		}
	};

	useEffect(() => {
		getCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openCustomersDetailsPage = useCallback(
		(customerId: number) => {
			navigate(generatePath(adminPages.customer.to, { customerId }));
		},
		[navigate],
	);

	const rows = useMemo(() => {
		return customers.map((customer) => ({
			id: customer.id,
			fullName: `${customer.lastName} ${customer.firstName}`,
			country: getCountryName(customer.address.countryIso),
			phoneNumber: customer.telephone1,
			createdAt: formatDate(customer.createdAt),
			actions: (
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
					</DropdownToggle>
					<DropdownMenu placement='bottom-end'>
						<DropdownItem
							onClick={() => openCustomersDetailsPage(customer.id)}
							icon='HeroEye'>
							voir
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			),
		}));
	}, [customers, openCustomersDetailsPage]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Clients' currentPage='Clients' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Clients'>
				<Container>
					<Card
						header={
							<>
								Clients
								{customers.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{customers.length}
									</Badge>
								) : null}
							</>
						}>
						<DataGrid keyPrefix='customers' columns={cols} rows={rows} />
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminCustomersPage;
