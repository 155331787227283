import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FieldWrap from '../components/form/FieldWrap';
import Input from '../components/form/Input';
import Validation from '../components/form/Validation';
import Icon from '../components/icon/Icon';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Alert from '../components/ui/Alert';
import Button from '../components/ui/Button';
import { authPages } from '../config/pages.config';
import useAxiosFunction from '../hooks/useAxiosFunction';
import useCustomToast from '../hooks/useToast';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import { ResetResponse } from '../types/api';

type TValues = {
	password: string;
	confirmPass: string;
};

const passwordSchema = Yup.object().shape({
	password: Yup.string()
		.required('Veuillez entrer votre mot de passe')
		.min(8, 'Le mot de passe doit avoir au moins 8 caractères')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			'Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial, et avoir au moins 8 caractères',
		),
	confirmPass: Yup.string()
		.oneOf([Yup.ref('password')], 'Les mots de passe ne correspondent pas')
		.required('Veuillez entrer votre mot de passe'),
});

const ResetPasswordPage = () => {
	const location = useLocation();
	const customToast = useCustomToast();
	const navigate = useNavigate();
	const { axiosFetch } = useAxiosFunction();

	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
	const [isResponseReceived, setIsResponseReceived] = useState<boolean>(false);

	const token = useMemo(() => {
		return location.search.substring(3);
	}, [location.search]);

	const formik = useFormik({
		initialValues: {
			password: '',
			confirmPass: '',
		},

		validationSchema: passwordSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				const response = await axiosFetch<ResetResponse>({
					method: 'POST',
					url: '/api/auth/reset-password',
					requestConfig: {
						password: values.password,
						verifToken: token,
					},
				});

				if (response?.message) {
					// customToast.success(response.message);
					setIsResponseReceived(true);
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const navigateToLogin = () => navigate(authPages.loginPage.to);

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit'
			name='Mise à jour du mot de passe'>
			<div className='container mx-auto flex items-center justify-center h-full'>
				<div className='flex max-w-sm flex-col gap-8'>
					<div>
						<LogoTemplate className='h-12' />
					</div>
					<div>
						<span className='text-4xl font-semibold'>Mise à jour du mot de passe</span>
					</div>

					<Link
						to={authPages.loginPage.to}
						className='flex items-center space-x-2 mt-4 text-xs'>
						<Icon icon='HeroArrowLeft' size='text-xl' color='sky' />
						<span>Accueil</span>
					</Link>
					{isResponseReceived ? (
						<Alert
							className='border-transparent'
							color='emerald'
							title='Mot de passe défini'
							icon='HeroCheckCircle'
							variant='solid'>
							Veuillez vous connecter pour accéder à votre compte. <br />
							<Button
								variant='solid'
								colorIntensity='50'
								onClick={navigateToLogin}
								className='mt-2'>
								Se connecter
							</Button>
						</Alert>
					) : (
						<div>
							{token ? (
								<form className='flex flex-col gap-4' noValidate>
									<div>
										<Validation
											isValid={formik.isValid}
											isTouched={formik.touched.password}
											invalidFeedback={formik.errors.password}>
											<FieldWrap
												firstSuffix={
													<Icon icon='HeroKey' className='mx-2' />
												}
												lastSuffix={
													<Icon
														className='mx-2 cursor-pointer'
														icon={
															passwordShowStatus
																? 'HeroEyeSlash'
																: 'HeroEye'
														}
														onClick={() => {
															setPasswordShowStatus(
																!passwordShowStatus,
															);
														}}
													/>
												}>
												<Input
													type={passwordShowStatus ? 'text' : 'password'}
													id='password'
													name='password'
													placeholder='Mot de passe'
													value={formik.values.password}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FieldWrap>
										</Validation>
									</div>
									<div>
										<Validation
											isValid={formik.isValid}
											isTouched={formik.touched.confirmPass}
											invalidFeedback={formik.errors.confirmPass}>
											<FieldWrap
												firstSuffix={
													<Icon icon='HeroKey' className='mx-2' />
												}
												lastSuffix={
													<Icon
														className='mx-2 cursor-pointer'
														icon={
															passwordShowStatus
																? 'HeroEyeSlash'
																: 'HeroEye'
														}
														onClick={() => {
															setPasswordShowStatus(
																!passwordShowStatus,
															);
														}}
													/>
												}>
												<Input
													type={passwordShowStatus ? 'text' : 'password'}
													id='confirmPass'
													name='confirmPass'
													placeholder='Confirmer le mot de passe'
													value={formik.values.confirmPass}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FieldWrap>
										</Validation>
									</div>
									<div>
										<Button
											size='lg'
											variant='solid'
											isLoading={formik.isSubmitting}
											isDisable={formik.isSubmitting}
											className='w-full font-semibold'
											onClick={() => formik.handleSubmit()}>
											Valider
										</Button>
									</div>
								</form>
							) : (
								<Alert
									className='border-transparent'
									color='red'
									icon='HeroExclamationTriangle'
									title='Lien corrompu!'
									variant='solid'>
									Le lien utilisé est invalide, veuillez suivre le lien dans le
									mail de réinitialisation de mot de passe que vous avez reçu.
								</Alert>
							)}
						</div>
					)}
				</div>
			</div>
		</PageWrapper>
	);
};
export default ResetPasswordPage;
