import { CuDeliveryAddress } from '@devschap/order-point-types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../components/form/Input';
import Button from '../../../components/ui/Button';

import Radio from '../../../components/form/Radio';
import { HeroBuildingOffice, HeroPhone } from '../../../components/icon/heroicons';
import Badge from '../../../components/ui/Badge';
import Card from '../../../components/ui/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import Modal from '../../../components/ui/Modal';
import useAddressForm from '../../../hooks/useAddressForm';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import useUserStore from '../../../hooks/useUserStore';

interface Props {
	deliveryAddress: CuDeliveryAddress;
	selectedAddressId?: number;
	setSelectedAddress?: Dispatch<SetStateAction<CuDeliveryAddress | null>>;
	readonly?: false;
}

const DeliveryAddress = ({
	deliveryAddress,
	selectedAddressId,
	setSelectedAddress,
	readonly,
}: Props) => {
	const [deleteModalStatus, setDeleteModalStatus] = useState<boolean>(false);
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [favoriteModalStatus, setFavoriteModalStatus] = useState<boolean>(false);
	const [label, setLabel] = useState<string>(deliveryAddress.label);
	const [telephone, setTelephone] = useState<string>(deliveryAddress.telephone ?? '');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { AddressForm, addressFormik, country } = useAddressForm({
		address: deliveryAddress.address,
		countryPlaceholder: 'Pays',
	});
	const { editDeliveryAddress, deleteDeliveryAddress, setPreferred } = useUserStore();
	const customToast = useCustomToast();
	const dispatch = useDispatch();
	const { axiosFetch } = useAxiosFunction();

	const editAddress = async () => {
		try {
			setIsLoading(true);
			const response = await axiosFetch<{ address: CuDeliveryAddress }>({
				method: 'PUT',
				url: '/api/customer/delivery-address',
				requestConfig: {
					id: deliveryAddress.id,
					label,
					telephone,
					address: {
						id: deliveryAddress.address.id,
						countryIso: country,
						city: addressFormik.values.city,
						district: addressFormik.values.district,
						postalCode: addressFormik.values.postalCode,
					},
				},
			});
			if (response?.address) {
				dispatch(editDeliveryAddress(response.address ?? []));
				setModalStatus(false);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
		}
	};

	const deleteAddress = async () => {
		try {
			const response = await axiosFetch<{ succeeded: boolean }>({
				method: 'DELETE',
				url: `/api/customer/delivery-address/${deliveryAddress.id}`,
			});

			if (response?.succeeded) {
				dispatch(deleteDeliveryAddress(deliveryAddress));
				setModalStatus(false);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
		}
	};

	const setPreferredDeliveryAddress = async () => {
		try {
			const response = await axiosFetch<{ address: CuDeliveryAddress }>({
				method: 'PUT',
				url: '/api/customer/delivery-address/preferred',
				requestConfig: {
					addressId: deliveryAddress.id,
				},
			});

			if (response?.address) {
				dispatch(setPreferred(deliveryAddress));
				setFavoriteModalStatus(false);
			}
		} catch (error) {
			customToast.error('Une erreur est survenue');
		}
	};

	const disable = addressFormik.values.district === '' || label === '' || telephone === '';

	return (
		<>
			<Card className='mt-4 border'>
				<div className='flex p-3'>
					<div className='flex relative items-center justify-center'>
						{setSelectedAddress ? (
							<Radio
								name='deliveryAddress'
								color='blue'
								selectedValue={selectedAddressId}
								value={deliveryAddress.id}
								onChange={() => setSelectedAddress(deliveryAddress)}
							/>
						) : null}
					</div>

					<div className='px-6 flex justify-between space-x-2  w-full'>
						<div className='space-y-1'>
							<div className='font-bold'>{deliveryAddress.label}</div>
							<div className='flex items-center gap-3'>
								<span>
									<HeroBuildingOffice />
								</span>
								<span> {deliveryAddress.address.district}</span>
							</div>
							<div className='flex items-center gap-3'>
								<span>
									<HeroPhone />
								</span>
								<span> {telephone}</span>
							</div>
						</div>
						<div className='flex justify-between items-center space-x-3'>
							{deliveryAddress.preferred === true ? (
								<Badge className='border-transparent' variant='outline'>
									Preférée
								</Badge>
							) : null}

							{readonly ? null : (
								<Dropdown>
									<DropdownToggle hasIcon={false}>
										<Button
											variant='outline'
											color='zinc'
											icon='HeroEllipsisVertical'
										/>
									</DropdownToggle>
									<DropdownMenu placement='bottom-end'>
										{deliveryAddress.preferred !== true && (
											<DropdownItem
												icon='HeroStar'
												onClick={() => setFavoriteModalStatus(true)}
												color='sky'>
												Preférée
											</DropdownItem>
										)}

										<DropdownItem
											icon='HeroPencil'
											onClick={() => setModalStatus(true)}>
											Editer
										</DropdownItem>
										<DropdownItem
											icon='HeroTrash'
											onClick={() => setDeleteModalStatus(true)}
											className='border-t-2 text-red-500'>
											Supprimer
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}
						</div>
					</div>
				</div>
			</Card>
			<Modal
				isOpen={modalStatus}
				size='sm'
				setIsOpen={setModalStatus}
				title="Modifer l'addresse de livraison"
				footer={
					<Button
						isLoading={isLoading}
						isDisable={disable}
						variant='solid'
						color='blue'
						className='w-full'
						onClick={editAddress}>
						Modifier
					</Button>
				}>
				<Input
					className='mb-4'
					id='phone'
					name='phone'
					type='tel'
					onChange={(e) => setTelephone(e.target.value)}
					value={telephone}
					placeholder={telephone}
				/>

				<Input
					className='mb-4'
					id='label'
					name='label'
					type='text'
					onChange={(e) => setLabel(e.target.value)}
					value={label}
					placeholder={label}
				/>

				{AddressForm}
			</Modal>

			<Modal
				isOpen={deleteModalStatus}
				setIsOpen={setDeleteModalStatus}
				footer={
					<Button
						variant='solid'
						isLoading={isLoading}
						onClick={deleteAddress}
						className='w-full'
						color='blue'>
						Supprimer
					</Button>
				}>
				Voulez-vous vraiment supprimer cette addresse ?
			</Modal>

			<Modal
				isOpen={favoriteModalStatus}
				setIsOpen={setFavoriteModalStatus}
				footer={
					<Button
						variant='solid'
						isLoading={isLoading}
						onClick={setPreferredDeliveryAddress}
						className='w-full'
						color='blue'>
						Valider
					</Button>
				}>
				Voulez-vous vraiment definir cette addresse commme preferée
			</Modal>
		</>
	);
};

DeliveryAddress.defaultProps = {
	selectedAddressId: undefined,
	setSelectedAddress: undefined,
	readonly: false,
};

export default DeliveryAddress;
