import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import { adminPages } from '../../../config/pages.config';

type Props = {
	orderId: number;
};

const AdminOrderAction = ({ orderId }: Props) => {
	const navigate = useNavigate();

	const openDetailsPage = useCallback(() => {
		navigate(generatePath(adminPages.order.to, { orderId }));
	}, [orderId, navigate]);

	return (
		<div>
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
				</DropdownToggle>
				<DropdownMenu placement='bottom-end'>
					<DropdownItem icon='HeroEye' onClick={openDetailsPage}>
						Voir
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default AdminOrderAction;
