import { FC, ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authPages } from '../config/pages.config';
import useAsideStatus from '../hooks/useAsideStatus';
import useFetchUser from '../hooks/useFetchUser';
import useLocalStorage from '../hooks/useLocalStorage';
import useOrderStore from '../hooks/useOrderStore';
import useUserStore from '../hooks/useUserStore';
import OpLoader from '../templates/layouts/Loader/OpLoader.template';
import { isInsideApp } from '../utils/ui.util';

export interface IAuthContextProps {
	onLogout: () => void;
	isLoading: boolean;
	loggedIn: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthProviderProps {
	children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { resetUser } = useUserStore();
	const { resetOrder } = useOrderStore();
	const [token] = useLocalStorage('token', null);
	const { setAsideStatus } = useAsideStatus();

	const { fetchUser, loading, state, loggedIn } = useFetchUser();

	useEffect(() => {
		if (isInsideApp(location.pathname) || token) {
			fetchUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// call this function to sign out logged-in user
	const onLogout = () => {
		localStorage.removeItem('token');
		dispatch(resetOrder());
		dispatch(resetUser());
		setAsideStatus(false);
		navigate(`../${authPages.loginPage.to}`, { replace: true });
	};

	const value: IAuthContextProps = useMemo(
		() => ({
			onLogout,
			isLoading: loading,
			loggedIn,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state, loading],
	);

	return (
		<AuthContext.Provider value={value}>
			{loading ? <OpLoader /> : children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
