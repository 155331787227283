import { CuCustomerMinimal } from '@devschap/order-point-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { DatagridColumnDef } from '../../../components/DataGrid';
import DeliveryInviteCustomerBanner from '../../../components/delivery-company/DeliveryInviteCustomerBanner';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Badge from '../../../components/ui/Badge';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/ui/Dropdown';
import EmptyContent from '../../../components/ui/EmptyContent';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';
import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import OpLoader from '../../../templates/layouts/Loader/OpLoader.template';
import { formatDate, getCountryName } from '../../../utils/ui.util';

const cols: DatagridColumnDef[] = [
	{
		field: 'id',
		label: 'ID',
	},
	{
		field: 'fullName',
		label: 'Nom complet',
		children: ['country', 'phoneNumber'],
	},
	{
		field: 'country',
		label: 'Pays',
	},
	{
		field: 'phoneNumber',
		label: 'Numéro',
	},
	{
		field: 'createdAt',
		label: 'Inscrit le',
		// hideOnMobile: true,
	},
	{
		field: 'actions',
		label: 'Actions',
	},
];

const DeliveryCompanyCustomersPage = () => {
	const { axiosFetch, loading } = useAxiosFunction();
	const customToast = useCustomToast();
	const [customers, setCustomers] = useState<CuCustomerMinimal[]>([]);

	const getCustomers = useCallback(async () => {
		if (loading) return;

		try {
			const response = await axiosFetch<{ customers: CuCustomerMinimal[] }>({
				method: 'GET',
				url: '/api/delivery-company/customers',
			});
			setCustomers(response?.customers ?? []);
		} catch (error) {
			customToast.error('Une erreur est survenue. Veuillez rafraichir la page');
		}
	}, [setCustomers, axiosFetch, customToast, loading]);

	useEffect(() => {
		getCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const rows = useMemo(() => {
		return customers.map((customer) => ({
			id: customer.id,
			fullName: `${customer.lastName} ${customer.firstName}`,
			country: getCountryName(customer.address.countryIso),
			phoneNumber: customer.telephone1,
			createdAt: formatDate(customer.createdAt),
			actions: (
				<Dropdown>
					<DropdownToggle hasIcon={false}>
						<Button variant='outline' color='zinc' icon='HeroEllipsisVertical' />
					</DropdownToggle>
					<DropdownMenu placement='bottom-end'>
						<DropdownItem onClick={() => {}} icon='HeroEye'>
							voir
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			),
		}));
	}, [customers]);

	const content = useMemo(() => {
		if (loading) {
			return <OpLoader />;
		}

		if (customers.length) {
			return <DataGrid keyPrefix='customers' columns={cols} rows={rows} />;
		}

		return (
			<EmptyContent
				message={
					<div className='flex flex-col space-y-2 justify-center items-center'>
						<span>Pas de clients</span>
					</div>
				}
			/>
		);
	}, [loading, customers, rows]);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb path='Pages / Clients' currentPage='Clients' />
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>

			<PageWrapper name='Clients'>
				<Container>
					<DeliveryInviteCustomerBanner />
					<Card
						header={
							<>
								Clients
								{customers.length ? (
									<Badge
										className='ml-2 border-transparent text-sm'
										variant='outline'>
										{customers.length}
									</Badge>
								) : null}
							</>
						}>
						{content}
					</Card>
				</Container>
			</PageWrapper>
		</>
	);
};

export default DeliveryCompanyCustomersPage;
