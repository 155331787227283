import Nav, { NavItem } from '../../../../components/layouts/Navigation/Nav';
import { adminPages, appPages } from '../../../../config/pages.config';

const AdminSidebar = () => {
	return (
		<Nav>
			<NavItem {...appPages.dashboard} />
			<NavItem {...adminPages.orders} />
			<NavItem {...adminPages.customers} />
			<NavItem {...adminPages.deliveryCompanies} />
			<NavItem {...adminPages.settings} />
			<NavItem {...adminPages.mails} />
			<NavItem {...adminPages.contactMessages} />
		</Nav>
	);
};

export default AdminSidebar;
