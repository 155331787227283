import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/layouts/Breadcrumb/Breadcrumb';
import Container from '../../../components/layouts/Container/Container';
import Header, { HeaderLeft, HeaderRight } from '../../../components/layouts/Header/Header';
import PageWrapper from '../../../components/layouts/PageWrapper/PageWrapper';
import Subheader, { SubheaderLeft } from '../../../components/layouts/Subheader/Subheader';
import ButtonLink from '../../../components/ui/ButtonLink';
import { adminPages } from '../../../config/pages.config';

import DefaultHeaderRightCommon from '../../../templates/layouts/Headers/_common/DefaultHeaderRight.common';
import AdminCustomersDeliveryAddresses from '../components/AdminCustomerDeliveryAddresses';
import CustomerDetails from '../components/AdminCustomerDetails';
import CustomerOrderDetails from '../components/AdminCustomerOrders';

const AdminCustomerDetailsPage = () => {
	const { customerId } = useParams();

	return (
		<>
			<Header>
				<HeaderLeft>
					<Breadcrumb
						path='Pages / Clients / Client'
						currentPage='Informations sur le client'
					/>
				</HeaderLeft>
				<HeaderRight>
					<DefaultHeaderRightCommon />
				</HeaderRight>
			</Header>
			<Subheader>
				<SubheaderLeft>
					<ButtonLink path={adminPages.customers.to} text='Clients' />
				</SubheaderLeft>
			</Subheader>

			<PageWrapper name='Client'>
				<Container>
					{customerId ? (
						<div className='grid grid-cols-12 gap-4 mt-6'>
							<div className='col-span-12 sm:col-span-12 lg:col-span-4 space-y-4 '>
								<CustomerDetails customerId={customerId} />
								<AdminCustomersDeliveryAddresses customerId={customerId} />
							</div>

							<div className='col-span-12 sm:col-span-12 lg:col-span-8'>
								<CustomerOrderDetails customerId={customerId} />
							</div>
						</div>
					) : null}
				</Container>
			</PageWrapper>
		</>
	);
};

export default AdminCustomerDetailsPage;
