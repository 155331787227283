import classNames from 'classnames';
import { useState } from 'react';
import useCustomToast from '../../hooks/useToast';
import Button from './Button';

type TStatusValue = 'Copy' | 'Copying' | 'Copied';
type TStatus = { [key in 'COPY' | 'COPYING' | 'COPIED']: TStatusValue };

const STATUS: TStatus = {
	COPY: 'Copy',
	COPYING: 'Copying',
	COPIED: 'Copied',
};

type TProps = {
	text: string;
	className?: string;
};

const CopyButton = ({ text, className }: TProps) => {
	const [state, setState] = useState<TStatusValue>(STATUS.COPY);
	const customToast = useCustomToast();

	const onCopy = async () => {
		setState(STATUS.COPYING);
		await navigator.clipboard.writeText(text);
		setState(STATUS.COPIED);
		customToast.success('Texte copié dans le press-papier');

		setTimeout(() => setState(STATUS.COPY), 1000);
	};

	const copied = state === STATUS.COPIED;

	return (
		<Button
			rightIcon={copied ? 'HeroClipboardDocumentCheck' : 'HeroClipboardDocumentList'}
			variant={copied ? 'solid' : 'default'}
			color={copied ? 'emerald' : 'blue'}
			isLoading={state === STATUS.COPYING}
			onClick={onCopy}
			className={classNames({ '!px-0': !copied }, className)}>
			{text}
		</Button>
	);
};

CopyButton.defaultProps = {
	className: undefined,
};

export default CopyButton;
