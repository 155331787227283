import { DeliveryCompanyStatsKeys } from '@devschap/order-point-types';
import StatsCard from '../../../components/StatsCard';
import DeliveryInviteCustomerBanner from '../../../components/delivery-company/DeliveryInviteCustomerBanner';
import Alert from '../../../components/ui/Alert';
import ButtonLink from '../../../components/ui/ButtonLink';
import StatsCardSkeleton from '../../../components/ui/skeletons/StatsCardSkeleton';
import { deliveryCompanyPages } from '../../../config/pages.config';
import useFetchStats from '../../../hooks/useFetchStats';
import useUserStore from '../../../hooks/useUserStore';
import { StatsMeta } from '../../../types/api';

const STATS_META: { [key in DeliveryCompanyStatsKeys]: StatsMeta } = {
	dcProfit: {
		icon: 'HeroBanknotes',
		bgColor: 'bg-emerald-500',
		label: 'Profit',
		info: "Le cummul des frais d'expédition sur les commandes en cours et livrées",
	},
	dcDeliveredOrders: {
		icon: 'HeroTruck',
		bgColor: 'bg-blue-500',
		label: 'Commandes livrées',
	},
	dcOrdersInProgress: {
		icon: 'HeroShoppingCart',
		bgColor: 'bg-amber-500',
		label: 'Commandes en cours',
		info: 'Le nombre total de commandes en cours et en attente de paiement',
	},
	dcCustomers: {
		icon: 'HeroUserGroup',
		bgColor: 'bg-violet-500',
		label: 'Clients',
		info: 'Les clients inscrit utilisant votre code de référence',
	},
};
const DeliveryCompanyDashboard = () => {
	const { state: user } = useUserStore();
	const { loading: loadingStats, stats } = useFetchStats({
		isUserActive: user.deliveryService?.actived,
	});

	// const requestNotification = useCallback(() => {
	// 	// Check if the browser supports notifications
	// 	if (!('Notification' in window)) {
	// 		console.log('This browser does not support notifications.');
	// 		return;
	// 	}
	// 	Notification.requestPermission().then((permission) => {
	// 		// set the button to shown or hidden, depending on what the user answers
	// 		console.log({ permission });

	// 		// notificationBtn.style.display = permission === "granted" ? "none" : "block";
	// 	});
	// }, []);

	// const sentNotification = useCallback(() => {
	// 	const img = '/to-do-notifications/img/icon-128.png';
	// 	const text = `HEY! Your task is now overdue.`;
	// 	const notification = new Notification('To do list', { body: text, icon: img });

	// 	// document.addEventListener("visibilitychange", () => {
	// 	// 	if (document.visibilityState === "visible") {
	// 	// 		// The tab has become visible so clear the now-stale Notification.
	// 	// 		notification.close();
	// 	// 	}
	// 	// });
	// }, []);

	if (!user.deliveryService) {
		return null;
	}

	const missingSocials = [
		user.deliveryService.pageFacebook,
		user.deliveryService.pageTiktok,
		user.deliveryService.webSiteUrl,
	].every((v) => !v);

	return (
		<div>
			<div className='grid grid-cols-12 gap-4 mb-4'>
				{loadingStats ? (
					<>
						<StatsCardSkeleton />
						<StatsCardSkeleton />
						<StatsCardSkeleton />
						<StatsCardSkeleton />
					</>
				) : (
					<>
						{stats.map((stat) => (
							<div className='col-span-12 sm:col-span-6 lg:col-span-3' key={stat.key}>
								<StatsCard
									stat={stat}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...STATS_META[stat.key as DeliveryCompanyStatsKeys]}
								/>
							</div>
						))}
					</>
				)}
			</div>

			{!user.deliveryService?.actived ? (
				<Alert
					className='border-transparent my-4'
					color='amber'
					icon='HeroExclamationCircle'
					title='Information de compte'
					variant='outline'>
					Votre compte est en cours de validation. Nous vous contacterons d'ici peu.
				</Alert>
			) : null}
			{missingSocials ? (
				<Alert
					className='border-transparent my-4'
					color='red'
					icon='HeroExclamationTriangle'
					title='Données requises'
					variant='outline'>
					Pour accélerer la validation de votre service, veuillez fournir le lien vers
					votre page Facebook, Tiktok ou votre site web.
					<ButtonLink
						rightIcon
						showBorder
						path={deliveryCompanyPages.settings.to}
						text='Mettre à jour'
						className='border-gray-400 !py-0 mt-2'
					/>
				</Alert>
			) : null}

			{/* <Button onClick={requestNotification}>Request </Button>
			<Button onClick={sentNotification} className='ml-4'>
				Send notification
			</Button> */}

			<DeliveryInviteCustomerBanner />

			{/* <div className='col-span-12 2xl:col-span-8'>area 1</div>
			<div className='col-span-12 2xl:col-span-4'>area 2</div>
			<div className='col-span-12 2xl:col-span-8'>area 3</div>
			<div className='col-span-12 2xl:col-span-4'>area 4</div> */}
		</div>
	);
};

export default DeliveryCompanyDashboard;
