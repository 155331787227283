import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DataProtection from '../components/DataProtection';
import SocialAuthButtons from '../components/auth/SocialAuthButtons';
import FieldWrap from '../components/form/FieldWrap';
import Input from '../components/form/Input';
import Validation from '../components/form/Validation';
import Icon from '../components/icon/Icon';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { authPages } from '../config/pages.config';
import useAxiosFunction from '../hooks/useAxiosFunction';
import useFetchUser from '../hooks/useFetchUser';
import useCustomToast from '../hooks/useToast';
import useUserStore from '../hooks/useUserStore';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import { SigninResponse } from '../types/api';
import { AccountRole } from '../types/shared-enums';

type TValues = {
	email: string;
	password: string;
};
const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Adresse email invalide')
		.required('Veuillez entrer votre adresse email'),
	password: Yup.string().required('Veuillez entrer votre mot de passe'),
});

const LoginPage = () => {
	const navigate = useNavigate();
	const { axiosFetch } = useAxiosFunction();
	const { fetchUser } = useFetchUser();
	const { setAccountData } = useUserStore();
	const dispatch = useDispatch();

	const customToast = useCustomToast();

	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: loginSchema,
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				const response = await axiosFetch<SigninResponse>({
					method: 'POST',
					url: '/api/auth/signin',
					requestConfig: {
						email: values.email,
						password: values.password,
					},
				});

				if (response === undefined) return;

				const { account, jwt } = response;
				localStorage.setItem('token', jwt);

				if (account.role === AccountRole.anonymous) {
					dispatch(
						setAccountData({
							accountId: account.id,
							email: account.email,
							provider: account.provider,
							verified: account.verified,
							actived: account.actived,
							userType: account.role,
						}),
					);
					navigate(authPages.onboardingPage.to, { replace: true });
				} else {
					await fetchUser();
				}
			} catch (e: any) {
				customToast.error('Une erreur est survenue');
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit'
			name='Se connecter'>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-sm flex-col gap-8'>
					<LogoTemplate className='h-12' />
					<span className='text-4xl font-semibold'>Se connecter</span>
					<span>Accedez à votre compte avec</span>

					<SocialAuthButtons />
					<div className='border border-zinc-500/25 dark:border-zinc-500/50' />

					<span>Ou continuez avec votre adresse email</span>

					<form className='flex flex-col gap-4' noValidate>
						<div>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										id='email'
										autoComplete='email'
										name='email'
										placeholder='Email'
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										type={passwordShowStatus ? 'text' : 'password'}
										autoComplete='current-password'
										id='password'
										name='password'
										placeholder='Mot de passe'
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div className='text-xs flex justify-end'>
							<Link to={authPages.forgotPasswordPage.to} className='inline-block'>
								Mot de passe oublié ?
							</Link>
						</div>
						<div>
							<Button
								size='lg'
								variant='solid'
								isLoading={formik.isSubmitting}
								isDisable={formik.isSubmitting}
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								Se connecter
							</Button>
						</div>
					</form>
					<DataProtection />
					<div>
						<span className='flex gap-2 text-sm'>
							<span className='text-zinc-400 dark:text-zinc-600'>
								Vous n'avez pas de compte?
							</span>
							<Link to={authPages.signupPage.to} className='hover:text-inherit'>
								Inscrivez-vous
							</Link>
						</span>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default LoginPage;
