import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import FieldWrap from '../components/form/FieldWrap';
import Input from '../components/form/Input';
import Validation from '../components/form/Validation';
import Icon from '../components/icon/Icon';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Alert from '../components/ui/Alert';
import Button from '../components/ui/Button';
import ButtonLink from '../components/ui/ButtonLink';
import { authPages } from '../config/pages.config';
import useAxiosFunction from '../hooks/useAxiosFunction';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import { ForgotResponse } from '../types/api';

type TValues = {
	email: string;
};

const ForgotPasswordPage = () => {
	const { axiosFetch } = useAxiosFunction();

	const [response, setResponse] = useState<{ message?: string; mailSent: boolean } | null>(null);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('Adresse email invalide')
				.required('Veuillez entrer votre adresse email'),
		}),
		onSubmit: async (values: TValues, { setSubmitting }) => {
			try {
				setResponse(null);
				const result = await axiosFetch<ForgotResponse>({
					method: 'POST',
					url: '/api/auth/forgot-password',
					requestConfig: {
						email: values.email,
					},
					suppressToast: true,
				});

				if (result) {
					setResponse({
						mailSent: result.mailSent ?? false,
						message: result.message,
					});
				} else {
					setResponse({ mailSent: false });
				}
			} catch (e: any) {
				setResponse({ mailSent: false, message: e.message });
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<PageWrapper
			isProtectedRoute={false}
			className='bg-white dark:bg-inherit'
			name='Récupération de compte'>
			<div className='container max-w-md mx-auto flex items-center justify-center h-full p-5'>
				<div className='flex max-w-xm flex-col gap-8'>
					<div>
						<LogoTemplate className='h-12' />
					</div>

					<div>
						<span className='text-4xl font-semibold'>
							Récupération <br className='md:hidden' /> de compte
						</span>
					</div>
					<div>
						<span>
							Veuillez entrer votre adresse email afin de <br className='md:hidden' />
							recupérer votre compte
						</span>
					</div>

					<form className='flex flex-col gap-4' noValidate>
						<ButtonLink path={authPages.loginPage.to} />

						{response ? (
							<Alert
								variant='solid'
								color={response.mailSent ? 'emerald' : 'red'}
								title={response.mailSent ? 'Mail envoyé' : 'Erreur'}
								icon={
									response.mailSent ? 'HeroEnvelope' : 'HeroExclamationTriangle'
								}
								isClosable>
								{response.mailSent
									? 'Veuillez consulter votre boite mail pour réinitialiser votre mot de passe'
									: response.message ?? 'Une erreur est survenue'}
							</Alert>
						) : null}
						<div>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.email}
								invalidFeedback={formik.errors.email}>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										id='email'
										autoComplete='email'
										name='email'
										placeholder='Email'
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>

						<div>
							<Button
								size='lg'
								variant='solid'
								isLoading={formik.isSubmitting}
								isDisable={formik.isSubmitting}
								className='w-full font-semibold mt-5'
								onClick={() => formik.handleSubmit()}>
								Envoyer
							</Button>
						</div>
					</form>
				</div>
			</div>
		</PageWrapper>
	);
};

export default ForgotPasswordPage;
