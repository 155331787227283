import { countries } from 'countries-list';
import { useCallback, useState } from 'react';
import { SingleValue } from 'react-select';
import SelectReact, { TSelectOption, TSelectOptions } from './form/SelectReact';

interface Props {
	placeholder?: string;
	countryIso: string;
	onChanged(countryCode: string): void;
}

export const countryOptions: TSelectOptions = Object.entries(countries)
	.map(([code, country]) => ({
		value: code,
		label: country.name,
	}))
	.sort((a, b) => a.label.localeCompare(b.label));

const CountrySelector = ({ placeholder, onChanged, countryIso }: Props) => {
	const [selectedCountry, setSelectedCountry] = useState<TSelectOption | null>(
		countryOptions.find((c) => c?.value === countryIso) ?? null,
	);

	const handleChange = useCallback(
		(value: SingleValue<TSelectOption>) => {
			if (!value) return;

			onChanged(value?.value);
			setSelectedCountry(value);
		},
		[onChanged],
	);

	return (
		<div>
			<SelectReact
				options={countryOptions}
				name='options'
				placeholder={placeholder ?? 'Selectionnez un pays'}
				value={selectedCountry}
				onChange={(value) => {
					handleChange(value as SingleValue<TSelectOption>);
				}}
			/>
		</div>
	);
};
CountrySelector.defaultProps = {
	placeholder: undefined,
};
export default CountrySelector;
