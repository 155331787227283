import { DcOrderMinimal } from '@devschap/order-point-types';
import { useEffect, useState } from 'react';
import useAxiosFunction from '../../../hooks/useAxiosFunction';
import useCustomToast from '../../../hooks/useToast';

const useDeliveryCompanyFetchOrders = () => {
	const [orders, setOrders] = useState<DcOrderMinimal[]>([]);
	const customToast = useCustomToast();
	const { axiosFetch, loading } = useAxiosFunction();

	const getOrders = async () => {
		if (loading) return;
		try {
			const response = await axiosFetch<{ orders: DcOrderMinimal[] }>({
				method: 'GET',
				url: '/api/delivery-company/orders',
			});
			setOrders(response?.orders ?? []);
		} catch (error) {
			customToast.error("Vous n'avez aucune commande");
		}
	};

	useEffect(() => {
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { loading, orders };
};

export default useDeliveryCompanyFetchOrders;
